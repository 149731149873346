<template>
    <v-navigation-drawer
        v-model="mDrawer"
        app
        :expand-on-hover="!$vuetify.breakpoint.mobile && !cosmeticSettings.drawerPermanent"
        :permanent="cosmeticSettings.drawerPermanent"
        style="height: 100%;">
        <v-list class="ma-0 pa-0">
            <v-list-item
                class="px-2 primary"
                dark
                dense>
                <v-list-item-avatar rounded class="mx-auto" size="36">
                    <v-img v-if="currentUser != null" :src="companyLogoURL(currentUser.companyAccountID)">
                        <template v-slot:placeholder><v-icon>mdi-account-outline</v-icon></template>
                    </v-img>
                </v-list-item-avatar>
                <v-list-item-action>
                    <v-row class="mr-1">
                        <v-btn
                            :to="{ name: 'office-portal-home' }"
                            icon>
                            <v-icon>mdi-home</v-icon>
                        </v-btn>
                        <!-- <BT-Skin-Menu /> -->
                        <v-btn
                            :to="{ name: 'settings' }"
                            icon
                            title="Company Settings">
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                        <v-btn
                            @click="$BlitzIt.style.toggleDrawerPermanent"
                            icon
                            title="Pin/Unpin Menu">
                            <v-icon>{{ cosmeticSettings.drawerPermanent ? 'mdi-pin-off' : 'mdi-pin' }}</v-icon>
                        </v-btn>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
            <!-- @click="$router.push({ name: 'my-profile' })" -->
            <v-list-item
                @click="$router.push({ name: 'settings', query: { slide: 'personal' } })"
                class="primary"
                dark
                dense>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        <p v-if="currentUser" class="my-auto">{{ currentUser.companyAccount.companyName }}</p>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ greeting }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <BT-Menu-Select
                buttonClass="ma-0 pa-0"
                canSelectNone
                icon="mdi-map-marker"
                itemText="locationName"
                itemValue="id"
                navigation="locations"
                right
                :small="false"
                v-model="cosmeticSettings.locationID"
                @change="changeLocation">
                <template v-slot:activator="{ on, attrs, select }">
                    <v-list-item
                        v-bind="attrs"
                        v-on="on"
                        class="v-step-location-selector primary lighten-1"
                        dark
                        dense>
                        <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ select != null ? select.locationName : 'Select Location' }}</v-list-item-title>
                            <v-list-item-subtitle v-if="select != null">{{ select | toLocationLine }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                </template>
            </BT-Menu-Select>

            <v-divider />
        </v-list>

        <v-list dense class="ma-0 pa-0 v-step-navigation-bar">
            <v-slide-y-transition group>
                <BT-App-Office-Drawer-Item
                    v-for="(item, index) in navigationItems"
                    :key="index"
                    :item="item"
                    :subFilter="currentSubscriptionViews"
                    :isSubGroup="false"/>
                <v-divider key="test"></v-divider>
            </v-slide-y-transition>
        </v-list>

        <template v-slot:append>
            <v-list class="ma-0 pa-0 v-step-subscription-toggle primary lighten-1" dark>
                <v-divider />
                <v-list-item v-if="cosmeticSettings != null && cosmeticSettings.subscriptionView != null" @click="toggleState">
                    <v-list-item-icon v-if="cosmeticSettings.subscriptionView == 0"><v-icon>mdi-account</v-icon></v-list-item-icon>
                    <v-list-item-icon v-else-if="cosmeticSettings.subscriptionView == 1"><v-icon >mdi-factory</v-icon></v-list-item-icon>
                    <v-list-item-icon v-else-if="cosmeticSettings.subscriptionView == 2"><v-icon >mdi-truck-fast-outline</v-icon></v-list-item-icon>
                    <v-list-item-title v-if="isLengthyArray(possibleStates) && possibleStates.length > cosmeticSettings.subscriptionView" >{{ possibleStates[cosmeticSettings.subscriptionView].name }}</v-list-item-title>
                    <v-list-item-action>
                        <v-btn icon @click.stop="navToDashboard" title="Go to dashboard" >
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider />
                <v-list-item @click="logout" class="v-step-logout primary lighten-1" dark>
                    <v-list-item-icon><v-icon >mdi-logout</v-icon></v-list-item-icon>
                    <v-list-item-title >Logout</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { isLengthyArray } from '~tools/helpers.js';
export default {
    name: 'BT-App-Office-Drawer',
    components: {
        BTAppOfficeDrawerItem: () => import('~components/BT-App-Office-Drawer-Item.vue'),
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        // BTSkinMenu: () => import('~components/BT-Skin-Menu.vue')
    },
    data: function() {
        return {
            mDrawer: false,
            possibleStates: [],
            currentUser: null,
            settingsLoaded: false
        }
    },
    props: {
        value: null
    },
    watch: {
        value(val) {
            if (val == false && this.cosmeticSettings.drawerPermanent == true) {
                this.$BlitzIt.style.toggleDrawerPermanent();
            }

            this.mDrawer = val;
        },
        mDrawer(val) {
            if (this.value != val) {
                if (val == false && this.cosmeticSettings.drawerPermanent == true) {
                    this.$BlitzIt.style.toggleDrawerPermanent();
                }

                this.$emit('input', val);
            }
        }
    },
    computed: {
        cosmeticSettings() {
            return this.$BlitzIt.style.root.cosmetics
        },
        greeting() {
            if (this.currentUser) {
                if (this.currentUser.firstName) {
                    return '@' + this.currentUser.firstName + ' ' + this.currentUser.lastName;
                }
                else {
                    return '@' + this.currentUser.userName;
                }
            }
            return '';
        },
        isLoggedIn() {
            return this.$BlitzIt.auth.session.isLoggedIn;
        },
        currentSubscriptionViews() {
            var v = this.cosmeticSettings.subscriptionView;
            if (v != null && this.possibleStates.length > v) {
                return this.possibleStates[v].codes;
            }

            var subCode = this.$BlitzIt.auth.session?.company?.subscriptionCode;

            return subCode != null ? [subCode] : null;
        },
        navigationItems() {
            if (this.currentSubscriptionViews != null) {
                return this.$BlitzIt.navigation.items
                    .filter(x => x.isInNavMenu !== false)
                    .filter(x => !isLengthyArray(x.subFilters) || x.subFilters.some(y => this.currentSubscriptionViews.some(subView => subView == y)));
            }
            else {
                return this.$BlitzIt.navigation.items.filter(x => x.isInNavMenu !== false);
            }
            
        }
    },
    async mounted() {
        this.currentUser = await this.$BlitzIt.store.get('my-profile', null, null, false, null, null); //await this.$BlitzIt.auth.getUserInfo(false);

        var preferredCode = this.currentUser.companyAccount.subscriptionCode;

        this.possibleStates = [];

        if (preferredCode == 'CUSTFREE' || preferredCode == 'CUST' || preferredCode == 'CLIENTFREE') {
            this.possibleStates.push({ name: 'Customer', codes: ['CUST','CUSTFREE','CLIENTFREE'] });
        }
        else if (preferredCode == 'COURFREE' || preferredCode == 'COUR') {
            this.possibleStates.push({ name: 'Customer', codes: ['CUST','CUSTFREE','CLIENTFREE'] });
            this.possibleStates.push({ name: 'Courier', codes: ['COUR','COURFREE'] });
        }
        else if (preferredCode == 'SUPPFREE' || preferredCode == 'SUPP') {
            this.possibleStates.push({ name: 'Customer', codes: ['CUST','CUSTFREE','CLIENTFREE'] });
            this.possibleStates.push({ name: 'Courier', codes: ['COUR','COURFREE'] });
            this.possibleStates.push({ name: 'Supplier', codes: ['SUPP','SUPPFREE'] });
        }
        
        var ind = this.possibleStates.findIndex(x => x.codes.some(code => code == preferredCode));
        if (ind >= 0) {
            this.$BlitzIt.style.setSubscription(ind)
            // this.cosmeticSettings.subscriptionView = ind;
        }

        // this.loadSettings();
    },
    methods: {
        saveSettings() {
            this.saveLocally('cosmetic', this.cosmeticSettings);
        },
        changeLocation(locationID) {
            this.$BlitzIt.style.setLocation(locationID);
            this.$BlitzIt.auth.setLocation(locationID);
        },
        navToDashboard() {
            const c = this.cosmeticSettings.subscriptionView;
            if (c == 0) {
                this.$router.push({ name: 'customer-dashboard' });
            }
            else if (c == 1) {
                this.$router.push({ name: 'courier-dashboard' });
            }
            else if (c == 2) {
                this.$router.push({ name: 'supplier-dashboard' });
            }
        },
        toggleState() {
            const c = this.cosmeticSettings.subscriptionView;

            if (c == (this.possibleStates.length - 1)) {
                this.$BlitzIt.style.setSubscription(0)
            }
            else {
                this.$BlitzIt.style.setSubscription(c + 1);
            }
        }
    }
}
</script>